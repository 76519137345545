import React from "react"
import { Routes, Route, Navigate} from "react-router-dom"
import "./App.css"
import "bootstrap/dist/css/bootstrap.min.css"
import NavBar from "./components/NavBar/NavBar"
import Footer from "./components/Footer/Footer"
import Info from "./components/Info/Info"
import ML_study from "./components/Info/ML_study"
import Map from "./components/Map/Map"

//TODO Web Template Studio: Add routes for your new pages here.
const App = () => {
    return (
            <React.Fragment>
                <NavBar />
                    <Routes>
                        <Route path="/" element={<Navigate to="/Map" />} />
                        <Route path="/Info" element={<Info />} />
                        <Route path="/ML_study" element={<ML_study />} />
                        <Route path="/Map" element={<Map />} />
                    </Routes>
                <Footer />
            </React.Fragment>
    );
}

export default App;

