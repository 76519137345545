import React, { useState, useEffect, useRef } from "react"
import 'leaflet/dist/leaflet.css'
import 'leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.webpack.css'
import 'leaflet-defaulticon-compatibility'
import * as L from 'leaflet'

import * as C from 'leaflet-coverage'
import * as CovJSON from 'covjson-reader'

import { Form, Alert } from 'react-bootstrap'

import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

import { parseISO } from 'date-fns'
import "../../../src/App.css"
// import {generatePath} from "react-router-dom";
//export const API_URL = 'https://159.162.23.57/'
//export const API_URL = 'http://localhost:8080/'
export const API_URL = 'https://swan.kartverket.no/api/'

const Map = () => {
  const [startDate, setStartDate] = useState(new Date())
  const [dates, setDates] = useState()
  const [useCases, setUsecases] = useState([])
  const [useCase, setUsecase] = useState([])
  const [thresholds, setThresholds] = useState([])
  const [threshold, setThreshold] = useState()
  //const [interval, setInterval] = useState(null)
  const [error, setError] = useState('Choose Use Case and treshold first, then pick a valid date')
  const [dataMissing, setDataMissing] = useState('')

  useEffect(() => {
    console.log('testing new gui')
    let tmp = 'threshold-type';
    if(useCase.length > 0) {
      tmp = 'threshold-type/' + useCase;
    }
    else {
      tmp = 'threshold-type';
    }

    console.log(tmp);
    console.log(useCase);


    fetch(API_URL + tmp)
      .then(response => {
        return response.json()
      })
      .then(json => {
        console.log(json)
        setThresholds(json.theshold_types)
      })
      .catch(e => {
        console.error(e)
      })
  }, [useCase])
  useEffect(() => {
    fetch(API_URL + 'datetime-range')
      .then(response => {
        return response.json()
      })
      .then(json => {
        const start_time = parseISO(json.start_time)
        //const end_time = parseISO(json.end_time)
        setStartDate(start_time)
        setDates([parseISO(json.start_time).toUTCString(), parseISO(json.end_time).toUTCString()])
        setInterval(json.interval)
      })
      .catch(e => {
        console.error(e)
      })
  }, [])
  useEffect(() => {
    fetch(API_URL + 'usecases')
      .then(response => {
        return response.json()
      })
      .then(json => {
        console.log(json)
        setUsecases(json.usecases)
      })
      .catch(e => {
        console.error(e)
      })
  }, [])

  const mapRef = useRef(null)
  useEffect(() => {
    const layerName = 'topo4' // topo4, topo4graatone, norges_grunnkart, norges_grunnkart_graatone, sjokartraster2
    const cacheUrl = 'https://opencache.statkart.no/gatekeeper/gk/gk.open_gmaps?layers=' + layerName + '&zoom={z}&x={x}&y={y}'
    const kv = L.tileLayer(cacheUrl, {
      id: 'kv',
      attribution: '<a href="https://kartverket.no/">Kartverket</a>',
    })
    const osm = L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
      id: 'osm',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    })
    mapRef.current = L.map('kart', {
      center: [60, 10],
      zoom: 3,
      layers: [osm, kv]
    })
  }, [])

  useEffect(
    () => {
      console.log('hei og hå')
      mapRef.current.eachLayer(function (layer) {
        if (layer.options.id === 'covjson') {
          mapRef.current.removeLayer(layer)
        }
      })
      let tid = startDate.toISOString()
      tid = tid.slice(0, -5)
      if (useCase.length === 0) {
        setError('Choose Use Case first')
      } else if (!threshold) {
        setError('Choose Treshhold first')
      }
      else {
        setError()
      }
      const bbox = mapRef.current.getBounds().toBBoxString()
      let layer
      CovJSON.read(
        API_URL + 'collections/' + useCase + '/area?datetime=' + tid + '%2B00:00' + '&coords=POLYGON((' + bbox + '))&parameter_name=' + threshold + '&f=covjson'
      ).then(function (coverage) {
        setDataMissing('')
        layer = C.dataLayer(coverage, { id: 'covjson', parameter: threshold })
          .on('afterAdd', function () {
            C.legend(layer).addTo(mapRef.current)
          })
          .addTo(mapRef.current)
      }).catch(e => {
        setDataMissing('No data for the choosen combination of date, useCase and threshold-type')
        console.error(e)
      })
      /*
      mapRef.current.on('click', function (e) {
        new C.DraggableValuePopup({
          layers: [layer]
        }).setLatLng(e.latlng).openOn(mapRef.current)
      })
      */
    },
    [useCase, threshold, startDate]
  )

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/OSGeo/PROJ/master/docs/plot/data/coastline.geojson')
      .then(response => {
        return response.json()
      })
      .then(json => {
        L.geoJSON(json, {
          id: 'coastlines',
          zIndex: 1001,
        }).addTo(mapRef.current)
      })
      .catch(e => {
        console.error(e)
      })
  }, [])

  return <main id="mainContent">
    <div className="container">
      <div className="row">
        <div className="col">
          <div id="kart" style={ { height: "500px", width: "100%" } }></div>
          {/* <Legend legendUrl={legendUrl} /> */ }
        </div>
        <div className="col-sm">
          <h3>Arctic Space Weather (testing new swan)</h3>

          <div>
            <div className="btn-group">
              <Form.Select aria-label="Use Case" onChange={ e => {
                setUsecase(e.target.value)
              } }>
                <option value={ '' }>Choose Use Case</option>
                { useCases ? useCases.map(item => {
                  return (<option key={ item.name } value={ item.name }>{ item.name }</option>
                  )
                }) : ''
                }
              </Form.Select>
              <Form.Select aria-label="Use Case" onChange={ e => {
                setThreshold(e.target.value)
              } }>
                <option value={ '' }>Choose Threshold-type</option>
                { thresholds ? thresholds.map(item => {
                  return (<option key={ item } value={ item }>{ item }</option>
                  )
                }) : ''
                }
              </Form.Select>

            </div>
          </div>
          The map shows the percentage chance of disruption due to ionospheric disturbances, for the selected Use Case, during a three hour interval starting at the selected date and time.
          For some use cases, you can also select the type of Threshold used: &quot;pROTI&quot; uses a threshold for the strength of the ionospheric noise, while &quot;pPosErr&quot; uses a threshold for the expected position error.
          For more information, see the Info page.
          { dates ? <Alert variant='light'>
            { 'First date is: ' + dates[0] + ' - Last date is: ' + dates[1] }
          </Alert> : ''
          }
          <DatePicker
            showTimeSelect
            selected={ startDate }
            onChange={ (date) => setStartDate(date) }
            dateFormat="MMMM d, yyyy HH:mm:ss"
            timeFormat="HH:mm"
          />
          <Alert variant='light'>
            Note that data gaps exist in the dataset, due to gaps in the input data
          </Alert>
          { error ? <Alert variant='warning'>
            { error }
          </Alert> : ''
          }
          { dataMissing ? <Alert variant='danger'>
            { dataMissing }
          </Alert> : ''
          }
        </div>
      </div>
    </div>
  </main>
}
export default Map
