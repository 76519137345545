import React from "react"

const Info = () => {
  return <main id="mainContent" role="main">
    <section className="jumbotron text-center">
      <div className="container">
        <h2>Information about the SWAN project</h2>
        <p></p>
      </div>
    </section>
    <div className="container">
      <div className="row">
        <div className="card">
          <div className="card-body">
            <div className="card-text">
              <p>
                The underlying model for the forecasts, &quot;HAPEE&quot; (High lAtitude scintillation Positioning Error Estimator), is an empirical, statistical, model. This means that it is based on large amounts of historical data to determine the likelihood of disturbances during different physical conditions. It uses information about the solar wind to calculate the probability of disturbances.
              </p>
              <p>
                The model provides forecasted distribution functions for noise in the ionospheric component of the GNSS signals, specifically as measured by ROTI (Rate Of TEC Index). This kind of disturbance is associated with positioning errors. By combining the forecasted distributions with thresholds, the end result is a percentage chance to exceed the threshold.
              </p>
              <p>
                As different use cases do not have the same sensitivity to disturbances, thresholds are defined separately for the use cases in order to provide the most relevant forecast.
                For some use cases, the relation between the ionospheric disturbances and the position errors have been characterized. For those use cases it is possible to select a threshold based on the forecasted position errors.
              </p>
              <table width="100%" cellPadding="0" cellSpacing="0">
                <colgroup><col width="26*" />
                  <col width="156*" />
                  <col width="34*" />
                  <col width="40*" />
                </colgroup>
                <tbody>
                  <tr valign="top">
                    <td width="10%"><p><b><span>Use
                      case</span></b></p>
                    </td>
                    <td width="61%"><p><b><span>Description</span></b></p>
                    </td>
                    <td width="13%"><p><b><span>ROTI
                      threshold</span></b></p>
                    </td>
                    <td width="16%"><p><b><span>Position
                      error threshold</span></b></p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="10%"><p><span>SFSC01</span></p>
                    </td>
                    <td width="61%"><p>
                      <span>Single Frequency, Single
                        Constellation.This represents a typical cheap/simple receiver
                        that tracks only a single frequency of a single constellation.
                        Small ionospheric disturbances are actually not a problem for
                        these, because other error sources are dominating, but they will
                        be degraded during strong events.</span></p>
                      <p>

                      </p>
                    </td>
                    <td width="13%"><p><span>3
                      TECU/min</span></p>
                    </td>
                    <td width="16%"><p><span>Not
                      yet defined</span></p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="10%"><p><span>MFSC01</span></p>
                    </td>
                    <td width="61%"><p><span>Multiple
                      Frequency, Single Constellation.This represents real-time use of
                      more advanced equipment that uses several frequencies but are
                      limited to a single constellation.</span></p>
                      <p>

                      </p>
                    </td>
                    <td width="13%"><p><span>1.75</span><span>
                      TECU/min</span></p>
                    </td>
                    <td width="16%"><p><span>Not
                      yet defined</span></p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="10%"><p><span>MFMC01</span></p>
                    </td>
                    <td width="61%"><p><span>Multiple
                      Frequency, Multiple Constellation.This represents real-time use
                      of professional equipment that is not limited by frequency or
                      constellation.</span>
                    </p>
                      <p>

                      </p>
                    </td>
                    <td width="13%"><p><span>2
                      TECU/min</span></p>
                    </td>
                    <td width="16%"><p><span>Not
                      yet defined</span></p>
                    </td>
                  </tr>
                  <tr valign="top">
                    <td width="10%"><p><span>PPP01</span></p>
                    </td>
                    <td width="61%"><p><span>Precise
                      Point Positioning.This represents multi-frequency,
                      multi-constellation data that has been post-processed using
                      precise orbit and clock information, achieving the highest
                      accuracy and precision.Due to its high accuracy, it will be
                      affected even by small disturbances.</span></p>
                      <p>

                      </p>
                    </td>
                    <td width="13%"><p><span>1.5
                      TECU/min</span></p>
                    </td>
                    <td width="16%"><p><span>5
                      cm</span></p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <p></p>
              <p>
                The original HAPEE model was derived by ONERA and NMA in the framework of a cooperation between { ' ' }
                <a href="https://cnes.fr/en">CNES</a>,{ ' ' }
                <a href="https://www.onera.fr/en">ONERA</a>,{ ' ' }
                <a href="https://kartverket.no/en">NMA</a> and{ ' ' }
                <a href="https://www.romsenter.no/">NOSA</a>.{ ' ' }
                An extended variant of HAPEE was derived in the <i>SWAN</i>  project, in cooperation between{ ' ' }
                <a href="https://www.space.dtu.dk/">DTU</a>,{ ' ' }
                <a href="https://www.onera.fr/en">ONERA</a>,{ ' ' }
                <a href="https://kartverket.no/en">NMA</a>,{ ' ' }
                <a href="https://spire.com/">SPIRE</a> and{ ' ' }
                <a href="https://www.c-core.ca/">CCORE</a>.{ ' ' }
                Data from Greenland has been added to the HAPEE modelling work to increase its validity and coverage for Arctic regions (especially in the vicinity of Greenland).
              </p>
              <p>
                Further details regarding the original HAPEE model can be found in the paper &quot; GNSS positioning error forecasting in the Arctic: ROTI and Precise Point Positioning error forecasting from solar wind measurements&quot; (<a href="https://doi.org/10.1051/swsc/2021024">https://doi.org/10.1051/swsc/2021024</a>).
              </p>
              <p>
                The data shown in the map is also available via an API.See the link in the top right corner for technical details.Several API endpoints are defined to provide different kinds of information.The API that provides the map is &quot;/collections/&#123; collectionId &#125;/area&quot;.It provides the data in the CoverageJSON format (<a href="https://www.w3.org/TR/covjson-overview/">https://www.w3.org/TR/covjson-overview/</a>).
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
}
export default Info
