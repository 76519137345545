import React from "react"
import { Link } from "react-router-dom"
import Container from 'react-bootstrap/Container'
import Navbar from 'react-bootstrap/Navbar'
import logo_kv from '../../assets/img/kartverket_logo.svg'
import logo_swan from '../../assets/img/logo_SWAN.jpg'
import logo_ccore from '../../assets/img/logo_CCore.png'
import logo_onera from '../../assets/img/logo_ONERA.png'
import logo_spire from '../../assets/img/logo_SPIRE.png'
import logo_dtu from '../../assets/img/logo_DTU.png'
import { API_URL } from '../Map/Map';

// A skip link is included as an accessibility best practice. For more information visit https://www.w3.org/WAI/WCAG21/Techniques/general/G1.
const NavBar = () => {
  return (
    <React.Fragment>
      <nav className="navbar py-0 navbar-expand-sm navbar-light border-bottom">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/" role="heading" aria-level="1">
            <img
              alt="SWAN"
              src={ logo_swan }
              //right="0"
              width="100"
              height="100"
              style={{ float: "right" }}  // Align image to the right
              className="d-inline-block align-top"
            />{ ' ' }
          </Link>
          <Container>
            <Navbar.Brand href="https://www.space.dtu.dk/">
              <img
                alt="ccore"
                src={ logo_dtu }
                //right="0"
                width="141"
                height="50"
                //style={{ float: "right" }}  // Align image to the right
                className="d-inline-block align-top"
              />{ ' ' }
            </Navbar.Brand>
            <Navbar.Brand href="https://www.onera.fr/en">
              <img
                alt="ONERA"
                src={ logo_onera }
                //right="0"
                width="141"
                height="50"
                //style={{ float: "right" }}  // Align image to the right
                className="d-inline-block align-top"
              />{ ' ' }
            </Navbar.Brand>
            <Navbar.Brand href="https://kartverket.no/en">
              <img
                alt="Kartverket"
                src={ logo_kv }
                //right="0"
                width="141"
                height="50"
                //style={{ float: "right" }}  // Align image to the right
                className="d-inline-block align-top"
              />{ ' ' }
            </Navbar.Brand>
            <Navbar.Brand href="https://spire.com/">
              <img
                alt="spire"
                src={ logo_spire }
                //right="0"
                width="141"
                height="50"
                //style={{ float: "right" }}  // Align image to the right
                className="d-inline-block align-top"
              />{ ' ' }
            </Navbar.Brand>
            <Navbar.Brand href="https://www.c-core.ca/">
              <img
                alt="ccore"
                src={ logo_ccore }
                //right="0"
                width="141"
                height="40"
                //style={{ float: "right" }}  // Align image to the right
                className="d-inline-block align-top"
              />{ ' ' }
            </Navbar.Brand>
          </Container>
          <div className="navbar-nav">
            <Link className="nav-item nav-link active" to="/info">
              Info
            </Link>
            <Link className="nav-item nav-link active" to="/ML_study">
              ML Study
            </Link>
            <Link className="nav-item nav-link active" to="/map">
              Map
            </Link>
            <nav><a className="nav-item nav-link active" target="_blank" rel="noopener noreferrer"
                    href={`${API_URL}swagger-ui/index.html`}>API</a></nav>
          </div>
        </div>
      </nav>
    </React.Fragment>
  )
}
export default NavBar
