import React from 'react'
import ReactMarkdown from 'react-markdown'
import img_ml from '../../assets/img/ML-diag.jpg'
import img_predictionzoom from '../../assets/img/predictionzoom-net-input2-seq3-pre12-ahe11.png'
//const file = require('../../assets/docs/SWAN_ContentForWebPage_fromONERA.md')
//console.log(file)
const markdown = `
Machine Learning study
--------------------------------

As part of the SWAN project, a study was performed on the possibility to use a machine learning approach to define a forecast model. This page describes the approach and the results achieved.

**Machine Learning Techniques**

- Prediction of the ionospheric scintillation index ROTI, in a given magnetic latitude interval, from solar wind parameters combined into one quantity, the Newell coupling function [2] and considering the magnetic local time.
- Selected type of neural network: LSTM NN (Long Short Term Memory neural networks) [3]
- Databases:
    - OMNI HRO database with 5-min resolution (for space measurements. [https://omniweb.gsfc.nasa.gov/](https://omniweb.gsfc.nasa.gov/))
    - GNSS database over Norway (for ground measurements)
- Regional forecasting: a magnetic latitude interval is set.

![ML-diag](https://swan.kartverket.no/static/media/ML-diag.jpg "ML-diag")


In order to use the LSTM neural network, several requirements have to be met:
- Data are organized into time series over an extended period for training as input features and output labels.
- The time series are continuous with a similar time step.


The Newell coupling function is derived from the solar wind data OMNI HRO, the ROTI index and the Magnetic Local Time (MLT) are extracted from GNSS data. These three time series are then organized into one database named *Machine learning database* to be used in the LSTM neural networks.

**Machine Learning results**

Forecasting of expected ROTI at t0 + 1h.

![predictionzoom-net-input2-seq3-pre12-ahe11](https://swan.kartverket.no/static/media/predictionzoom-net-input2-seq3-pre12-ahe11.png "predictionzoom-net-input2-seq3-pre12-ahe11")
This figure shows a zoom on the time series from the Test database.

- LSTM NN manages to capture the global trend.
- The prediction of scintillation event levels are not accurate enough.
- Promising results anyway: only two input features (space measurements NCF and MLT) to predict GNSS ground measurements.

**Conclusion and Outlook**


The objective of this work was to investigate the relevance of deep learning in Space Weather Forecast/Warning products derivation. This part dedicated to time series forecasting of ionospheric scintillation index from solar wind data remains a feasibility study. Obviously, the deployed machine learning techniques are preliminary, the chosen approach based on LSTM neural network is highly prospective and is used for the first time in this domain.

It could be relevant to build an exhaustive comparison with other methods such as Support Vector Machine or Multi-Layer Perceptron. Moreover, the designed model is focused on high latitudes interval, i.e. between 70° and 85° of magnetic latitudes. This interval includes active regions such as the cusp and the auroral oval but it remains a coarse approximation since it certainly mixes all the physical events happening in this area and it certainly melt ionospheric scintillation event and noise. A direct improvement could be the consideration of reduced MLAT area or/and the addition of MLAT as an input feature. Also, the few number of input features, namely the Newell coupling function and the Magnetic local time, reduces the overall understanding and describing of the global physical processes. In the future, considering more parameters as input feature, like the flow pressure, could increase the accuracy of the prediction.

Another point is the used space measurements; OMNI HRO database with 5-min resolution cannot be considered as an operational service. Indeed, these measurements are based on the ACE data and additional processing is added to improve the quality of the data in particular. For example, it has been noted that the ACE solar wind measurements contain at times spikes in mainly the plasma data. Further work is still necessary to assess the reliability of the model using ACE data to set an operational space weather service.

The selection of the train dataset and test dataset gets a large influence on the prediction performance. Here, the train is made considering ROTI data between 2010 and 2015 and the test is done for the years 2015 and 2016. Obviously, these times correspond to very different parts of the solar cycle and so, it affects the LSTM neural network capacities. Finally, the hyper parameters used to build the LSTM NN largely affect the global performance of the model. This is one of the main drawbacks in the machine learning techniques because no absolute relation is available to choose the best architecture.


Nonetheless, the suggested model paves the way for a new kind of operational service based on data driven methods. Even with a simple approach linking one type of solar wind data, magnetic local time and the ROTI, the LSTM NN should be able to blindly depict and learn a coarse regular pattern, which is the strength of this approach. Further work is needed to improve and get a relevant forecast model but a smart complementarity between a physically-based method like the HAPEE model and data-based techniques like the LSTM NN. This work will be continued by ONERA and NMA in the future.



**Bibliography**

[1] V. Fabbro, K. S. Jacobsen, , Y. L.Andalsvik, , and S. Rougerie. GNSS positioning error forecasting in the arctic: Roti and precise point positioning error forecasting from solar wind measurements. J. Space Weather Space Clim., 11:43, 2021. doi: 10.1051/swsc/2021024. URL https://doi.org/10.1051/swsc/2021024 .

[2] P. T. Newell, T. Sotirelis, K. Liou, C.-I. Meng, and F. J. Rich. A nearly universal solar wind-magnetosphere coupling function inferred from 10 magnetospheric state variables. Journal

of Geophysical Research: Space Physics, 112(A1), 2007. ISSN 2156-2202. doi: [https://doi.org/10.1029/2006JA012015](https://doi.org/10.1029/2006JA012015).

[3] R. Fu, Z. Zhang and L. Li, "Using LSTM and GRU neural network methods for traffic flow prediction," 2016 31st Youth Academic Annual Conference of Chinese Association of Automation (YAC), 2016, pp. 324-328, doi: 10.1109/YAC.2016.7804912
`

const Onera = () => {
    console.warn(img_ml)
    console.warn(img_predictionzoom)
    console.warn(markdown)
    return <main id="mainContent" role="main">
        <section className="jumbotron">
            <ReactMarkdown>{markdown}</ReactMarkdown>
        </section>
    </main>
  }
  export default Onera
  