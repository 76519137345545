import React from "react"

const Footer = () => {
  return (
    <footer className="footer mt-auto py-1 border-top">
      <div className="container">
        <div className="">
            This service was developed as part of the ESA project
            <blockquote>Forecasting Space Weather Impacts on Navigation Systems in the Arctic (Greenland Area) <cite>(Ref. ESA AO/1-9961/20/NL/AS).</cite></blockquote>
        </div>
      </div>
    </footer>
  )
}
export default Footer